import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { initiateSocket, socket} from './socket';
import Login from './pages/Login';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import LiveChat from './pages/LiveChat';
import TicketManagement from './pages/TicketManagement';
import ProtectedRoute from './components/ProtectedRoute';
import KnowledgeBase from './pages/KnowledgeBase';

function App() {
  useEffect(() => {
    // console.log('🔄 useEffect running - component mounted');
    
    initiateSocket();
    // console.log('🔌 Socket initialized');
  
    return () => {
      // console.log('🧹 Cleanup function running - component will unmount');
      if(socket) {
        socket.disconnect();
        // console.log('❌ Socket disconnected');
      }
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="LiveChat" element={<LiveChat />} />
            <Route path="ticket" element={<TicketManagement />} />
            <Route path="knowledgebase" element={<KnowledgeBase />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
