import io from "socket.io-client";

// const SOCKET_URL = "http://localhost:4001";
const SOCKET_URL = "https://tfb-back.4xp.chat";
const adminId = localStorage.getItem("adminId");
const adminType = 'admin'; // 'admin' or 'superadmin'

export const socket = io(SOCKET_URL, {
  reconnectionAttempts: 5,
  timeout: 10000,
});

export const initiateSocket = () => {
  if(!socket.connected) {
    socket.connect();
  }

  socket.on("connect", () => {
    if(adminId){
      console.log(`Connected to socket server with adminId: ${adminId}`);
      socket.emit("register", { userId: adminId, userType: adminType });
    } else {
      console.log(`Connected to socket server without adminId`);
    }
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from socket server");
  });

  socket.on("connect_error", (error) => {
    console.error("Connection error:", error);
  });

  socket.on("reconnect_attempt", () => {
    console.log("Reconnecting.....");
  });
};

  // Handle reconnection attempts
  socket.on("reconnect", () => {
    console.log("Reconnected to server. Re-registering admin...");
    socket.emit("register", { userId: adminId, userType: adminType });
});

export const registerAdmin = () => {
  const currentAdminId = localStorage.getItem("adminId");
  if (currentAdminId && socket.connected) {
    console.log(`Registering admin with ID: ${currentAdminId}`);
    socket.emit("register", { userId: currentAdminId, userType: adminType });
  }
};
