import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerAdmin } from '../socket';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const [timer, setTimer] = useState(60);
  const [loginResponse, setLoginResponse] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  // Timer effect for OTP expiration
  useEffect(() => {
    let interval = null;
    if (isVisible) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }
    if (timer === 0) {
      toast.error("OTP expired, please request a new one.");
      setIsVisible(false); // Hide OTP input
      setTimer(60); // Reset the timer
    }
    return () => clearInterval(interval);
  }, [isVisible, timer]);

  // Handle login and initial OTP sending
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // const response = await axios.post("http://localhost:4001/login", {
        const response = await axios.post("https://tfb-back.4xp.chat/login", {
        email: formData.email,
        password: formData.password,
      });
      console.log("Login response:", response.data);

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("adminId", response.data.adminId);
        console.log("Login successful, token stored");
        setLoginResponse(response.data); // Store login response
        sendOtp(formData.email); // Send OTP after successful login
      } else {
        console.log("Login failed, no token received");
        toast.error("Login failed. Please check your credentials and try again.");
      }
    } catch (error) {
      console.error("Login request failed:", error);
      if (error.response && error.response.status === 401) {
          // alert("Invalid credentials. Please check your email and password.");
          toast.error("Invalid credentials. Please check your email and password.");
      } else if (error.response && error.response.status === 404) {
          // alert("User not found. Please check your email.");
          toast.error("User not found. Please check your email.");
      } else {
          // alert("Login request failed. Please try again later.");
          toast.error("Login request failed. Please try again later.");
      }
    }
  };

  // Function to send OTP
  const sendOtp = async (email) => {
    try {
      // const response = await axios.post("http://localhost:4001/otp/sendOtp", {
      const response = await axios.post("https://tfb-back.4xp.chat/otp/sendOtp", {
        email,
      });
      console.log("OTP sent response:", response.data);
      setIsVisible(true); // Enable OTP input if the OTP is successfully sent
      setTimer(180); // Reset and start the timer for OTP
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Failed to send OTP. Please try again later.");
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = async () => {
    try {
      // const response = await axios.post("http://localhost:4001/otp/verifyOtp", {
      const response = await axios.post("https://tfb-back.4xp.chat/otp/verifyOtp", {
        email: formData.email,
        otp: formData.otp,
      });
      console.log("OTP verification response:", response.data);
      if (response.data.message === "OTP verified successfully.") {
        registerAdmin();
        navigate("/home/dashboard");
      } else {
        toast.error("Invalid or expired OTP");
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      toast.error("OTP verification failed. Please try again later.");
    }
  };

  // Handle input field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="bg-gray-200 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <ToastContainer autoClose={3000} pauseOnHover={true} />
        <a
          href="#"
          className="flex items-center mb-6 text-3xl font-semibold text-gray-900 dark:text-white"
        >
          TFB Chat Admin
        </a>
        <div className="w-full bg-gray-300 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 pr-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-400"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>
              {isVisible && (
                <div>
                  <label
                    htmlFor="otp"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Enter OTP
                  </label>
                  <input
                    type="text"
                    name="otp"
                    id="otp"
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="OTP"
                    required
                  />
                  <p className="text-sm text-gray-600">
                    Time remaining: {timer} seconds
                  </p>
                  <button
                    type="button"
                    onClick={handleVerifyOtp}
                    className="w-full text-white bg-header-gradient focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Verify OTP
                  </button>
                </div>
              )}
              <button
                type="submit"
                className="w-full text-white bg-header-gradient focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
